<template>
	<div class="bg-white padding stu-info">
		<border-title titleName="客户信息" />
		<div v-show="detail.id" class="margin-top flex aligin-items-center bg-z padding">
			<span class="padding-right">
				<span class="text-bold text-lg">{{ detail.UserName }}</span>
				<span class="margin-left-sm">(电话:{{ detail.PhoneNumber1 }})</span>
				<span class="border-right padding-right"></span>
			</span>
			<span class="padding-left">
				<span class="margin-left-sm">最后联系时间:</span>
				<span class="margin-left-sm">{{ detail.RecentNewsTime | dateTimeFilter}}</span>
			</span>
		</div>
		<div class="border-dashed padding flex justify-content-center aligin-items-center bg-z margin-top cursor"
			@click="chooseContect">
			<i class="el-icon-s-custom el-icon--left"></i>
			点击{{ detail.id ? '更换':'添加' }}客户
		</div>
	</div>
</template>

<script>
	export default {
		name: "CustomerInfo",
		data() {
			return {
				detail: {},
			};
		},
		props: {
			Detail: {
				type: String,
				default: ""
			}
		},
		created() {
			if(this.Detail){
				this.detail=JSON.parse(this.Detail)
			}
		},
		mounted() {},
		methods: {
			chooseContect() {
				this.$Dialog.ChooseContact({
					single: true
				}).then((res) => {
					this.detail = res || this.detail;
					this.$emit('change', res);
				});
			},
		},
	}
	// watch: {
	// 	detail: {
	// 		// console.log(this.detail)
	// 		handler(val) {
	// 			// do something, 可使用this
	// 			this.detail = JSON.parse(this.Detail.detail)
	// 		},
	// 		deep: true
	// 	}
	// }
</script>
