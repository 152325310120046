<template>
    <div>
        <edit-order @change="change" :Message="detail"></edit-order>
    </div>
</template>
<script>
import editOrder from './components/editOrder'
export default {
    components:{
        editOrder
    },
	name:"addOrder",
    data() {
        return {
		
            detail:"",
        }
    },
	created() {
		if (this.$route.params.detail) {
			this.detail = this.$route.params.detail;
		}
	},
	
    methods: {
        change(res){
            this.$router.back()
        },
    },
}
</script>