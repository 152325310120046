<template>
  <div class="margin-top padding bg-white">
    <border-title titleName="学员信息" />
    <div class="margin-top flex flex-wrap aligin-items-center">
      <div
        class="flex aligin-items-center margin-top"
        style="margin-right: 20px"
      >
        <span>学员姓名</span>
        <div class="margin-left">
          <el-input size="mini" v-model="ruleForm.student_name"></el-input>
        </div>
      </div>
      <div
        class="flex aligin-items-center margin-top"
        style="margin-right: 20px"
      >
        <span>学员电话</span>
        <div class="margin-left">
          <el-input size="mini" v-model="ruleForm.student_phone"></el-input>
        </div>
      </div>
      <div class="flex flex-wrap margin-top aligin-items-center">
        <span>联系人</span>
        <div class="margin-left flex flex-center flex-wrap">
          <el-input
            style="width: 20em; margin-right: 10px"
            size="mini"
            v-for="(item, index) in contacts"
            :key="index"
            v-model="item.phone"
          >
            <el-select
              style="width: 5em"
              size="mini"
              v-model="item.relation"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in relations"
                :key="index"
                :label="item.Name"
                :value="item.Key"
              ></el-option>
            </el-select>
            <i
              v-show="index > 0"
              slot="append"
              class="el-icon-delete cursor"
              @click="handleDelContact(index)"
              title="删除"
            ></i>
          </el-input>
        </div>

        <i
          title="添加"
          class="el-icon-circle-plus cursor"
          style="color: #409eff"
          @click="handleAddContact"
          v-show="false"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentInfo",
  data() {
    return {
      saleSourceList: [],
      ruleForm: {
        student_name: "",
        student_phone: "",
      },
      contacts: [{ phone: "", relation: "" }],
      relations: [],
    };
  },
  created() {
    this.API.SystemGetSettings("SysRelationshipEnum", false).then((res) => {
      this.relations = res;
    });
  },
  mounted() {},
  watch: {
    ruleForm: {
      deep: true,
      handler(val) {
        this.change();
      },
    },
    contacts: {
      deep: true,
      handler() { 
        this.change();
      },
    },
  },
  methods: {
    change() {
      this.$emit("change", {
        ...this.ruleForm,
        contacts: this.contacts,
      });
    },
    handleDelContact(index) {
      this.contacts.splice(index, 1);
    },
    handleAddContact() {
      this.contacts.push({ phone: "", relation: "" });
    },
  },
};
</script>

<style scoped>
>>> .el-select .el-input {
  width: 160px;
}
</style>
